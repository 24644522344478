@import url(https://www.contentstack.com/sdks/contentstack-ui-extensions/dist/1.1.0/ui-extension-sdk.css);
.config-wrapper .Form__item{width:500px}.config-wrapper .tippy-wrapper{margin-right:10px}.config-wrapper .radio-wrapper{padding:20px 10px}.config-wrapper .radio-button{margin-right:20px}.config-wrapper .toggle-save-response{padding:20px 10px}
.tbl{margin-left:0}.layout-container{display:flex;flex-direction:column}.errormsg{font-family:Arial,Helvetica,sans-serif;font-weight:700;margin-left:0;margin-right:0;margin-top:30px;background-color:#eff1f4;width:100%;height:auto;font-size:23px;text-align:center}#tag{margin:0 5px 5px 15px;background-color:#fff;width:85px}#loader{display:none;margin-left:45%;margin-top:50%}.metric-details{margin-top:5px;margin-left:10%}.line{height:2px;color:#efefef;margin-top:24px;margin-left:20px;margin-right:20px;margin-bottom:24px}p{font-size:14px;margin:0}h1{color:#4f6471;font-size:24px;margin:0;line-height:1}a{text-decoration:none;color:#4f6471}a:hover{color:#25bd9f}.sidebar-wrapper{display:flex;flex-direction:column;font-size:1.1rem !important}.sidebar-wrapper .sidebar-field{font-size:1.1rem}.sidebar-wrapper .entry-wrapper{margin-top:10px}
.errormsg{font-family:Arial,Helvetica,sans-serif;font-weight:bold;margin-left:0;margin-right:0;margin-top:0px;background-color:#eff1f4;width:100%;height:auto;font-size:23px;text-align:center}#tag{margin:0 5px 7px 17px;background-color:#fff;text-align:center;justify-content:center}#loader{display:none;margin-left:0;margin-top:0;text-align:center;width:100%;position:absolute;top:50%;-webkit-transform:translateY(-50%);transform:translateY(-50%);z-index:1}.metric-details{margin:2px 2px 0 40px}.metric-details p{font-size:14px;color:#748590;line-height:22px;font-family:proximaNovaSemiBold}.metric-details h1{font-size:20px;color:#4f6471;line-height:22px;font-family:proximaNovaSemiBold}.line{height:2px;color:#efefef;margin-top:24px;margin-left:20px;margin-right:20px;margin-bottom:24px}p{font-size:14px;margin:0}h1{color:#4f6471;font-size:24px;margin:0;line-height:1}a{text-decoration:none;color:#4f6471}a:hover{color:#25bd9f}.graph-wrap{width:49.9%;display:inline-block;position:relative;left:-15px}#canvasbody::before,#canvasbody::after{content:"";display:table}.Dashboard-field{font-size:1.1rem !important;padding-left:12px}
.config-wrapper .Form__item{width:500px}.config-wrapper .tippy-wrapper{margin-right:10px}.config-wrapper .radio-wrapper{padding:20px 10px}.config-wrapper .radio-button{margin-right:20px}.config-wrapper .toggle-save-response{padding:20px 10px}.swatch{padding:5px;background:#fff;border-radius:1px}
.app{display:flex}.app .layout-container{width:100%;display:flex}.app .layout-container .page-wrapper{display:flex;flex-direction:row;padding:60px 50px}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

