.app {
  display: flex;
  .layout-container {
    width: 100%;
    display: flex;
    .page-wrapper {
      display: flex;
      flex-direction: row;
      padding: 60px 50px;
    }
  }
}
