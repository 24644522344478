@import url("https://www.contentstack.com/sdks/contentstack-ui-extensions/dist/1.1.0/ui-extension-sdk.css");
.tbl {
  margin-left: 0;
}
.layout-container {
  display: flex;
  flex-direction: column;
}
.errormsg {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  margin-left: 0;
  margin-right: 0;
  margin-top: 30px;
  background-color: #eff1f4;
  width: 100%;
  height: auto;
  font-size: 23px;
  text-align: center;
}
#tag {
  margin: 0 5px 5px 15px;
  background-color: #fff;
  width: 85px;
}
#loader {
  display: none;
  margin-left: 45%;
  margin-top: 50%;
}
.metric-details {
  margin-top: 5px;
  margin-left: 10%;
}
.line {
  height: 2px;
  color: #efefef;
  margin-top: 24px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 24px;
}
/* #link {
  display: none;
} */
p {
  font-size: 14px;
  margin: 0;
}
h1 {
  color: #4f6471;
  font-size: 24px;
  margin: 0;
  line-height: 1;
}
a {
  text-decoration: none;
  color: #4f6471;
}
a:hover {
  color: #25bd9f;
}

.sidebar-wrapper {
  display: flex;
  flex-direction: column;
  font-size: 1.1rem !important;
  .sidebar-field {
    font-size: 1.1rem;
  }
  .entry-wrapper {
    margin-top: 10px;
  }
}
