.errormsg {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0px;
  background-color: #eff1f4;
  width: 100%;
  height: auto;
  font-size: 23px;
  text-align: center;
}

#tag {
  margin: 0 5px 7px 17px;
  background-color: white;
  /* width: 85px; */
  text-align: center;
  justify-content: center;
}

#loader {
  display: none;
  margin-left: 0;
  margin-top: 0;
  text-align: center;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.metric-details {
  margin: 2px 2px 0 40px;
}
.metric-details p {
  font-size: 14px;
  color: #748590;
  line-height: 22px;
  font-family: proximaNovaSemiBold;
}
.metric-details h1 {
  font-size: 20px;
  color: #4f6471;
  line-height: 22px;
  font-family: proximaNovaSemiBold;
}

.line {
  height: 2px;
  color: #efefef;
  margin-top: 24px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 24px;
}

/* #link {
  display: none;
} */

p {
  font-size: 14px;
  margin: 0;
}

h1 {
  color: #4f6471;
  font-size: 24px;
  margin: 0;
  line-height: 1;
}

a {
  text-decoration: none;
  color: #4f6471;
}

a:hover {
  color: #25bd9f;
}
.graph-wrap {
  width: 49.9%;
  display: inline-block;
  position: relative;
  left: -15px;
}
#canvasbody::before,
#canvasbody::after {
  content: "";
  display: table;
}

.Dashboard-field {
  font-size: 1.1rem !important;
  padding-left: 12px;
}
