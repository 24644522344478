.config-wrapper {
  .Form__item {
    width: 500px;
  }
  .tippy-wrapper {
    margin-right: 10px;
  }

  .radio-wrapper {
    padding: 20px 10px;
  }

  .radio-button {
    margin-right: 20px;
  }

  .toggle-save-response {
    padding: 20px 10px;
  }
}

.swatch {
  padding: 5px;
  background: #fff;
  border-radius: 1px;
}
